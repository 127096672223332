import axios from 'axios';
import APIUtils from './APIUtils';
import { Point } from 'geojson';
import { Provider } from './ProviderUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';
import LanguageDesignationUtils, {
  LanguageDesignation,
} from './LanguageDesignationUtils';
import MarkerUtils, { Marker } from './MarkerUtils';
import ProvinceUtils, { Province } from './ProvinceUtils';
import CommunityUtils, { Community } from './CommunityUtils';
import SectorsOfCareUtils, { SectorOfCare } from './SectorsOfCareUtils';
import ServiceUtils, { Service } from './ServiceUtils';

const language_service_keys = ['en', 'fr'];

interface PointOfService {
  id: number;
  service_provider?: Partial<Provider>;
  name?: LanguageObject;
  description?: LanguageObject;
  department?: LanguageObject;
  address: string;
  geojson: Point;
  uri?: LanguageObject;
  phone_number?: string;
  language_capability_keys: string[];
  language_services: {
    [locale: string]: boolean;
  };
  language_designation?: Partial<LanguageDesignation>;
  marker?: Partial<Marker>;
  province?: Partial<Province>;
  community?: Partial<Community>;
  sectors_of_care?: Partial<SectorOfCare>[];
  services?: Partial<Service>[];
  override_provider_services: boolean;
  data_quality?: {
    geocoder_confidence: number;
  };
}

const generateLanguageServices = (language_services?: {
  [locale: string]: boolean;
}) => {
  const result: any = {};
  language_service_keys.forEach((locale) => {
    result[locale] = language_services ? language_services[locale] : false;
  });
  return result;
};

const pointOfService = {
  generateDefault: (
    item?: Partial<PointOfService>
  ): Partial<PointOfService> => {
    return {
      id: item?.id,
      service_provider: {
        id: item?.service_provider?.id,
      },
      name: LocaleUtils.langobj.generate(item?.name),
      description: LocaleUtils.langobj.generate(item?.description),
      department: LocaleUtils.langobj.generate(item?.department),
      address: item?.address || '',
      geojson: {
        type: item?.geojson?.type || 'Point',
        coordinates: item?.geojson?.coordinates || [0, 0],
      },
      uri: LocaleUtils.langobj.generate(item?.uri),
      phone_number: item?.phone_number,
      language_capability_keys: item?.language_capability_keys || [],
      language_services: generateLanguageServices(item?.language_services),
      language_designation:
        LanguageDesignationUtils.languageDesignation.generateDefault(
          item?.language_designation
        ),
      marker: MarkerUtils.marker.generateDefault(item?.marker),
      province: ProvinceUtils.province.generateDefault(item?.province),
      community: CommunityUtils.community.generateDefault(item?.community),
      sectors_of_care: (item?.sectors_of_care || [])?.map((value) =>
        SectorsOfCareUtils.sectorOfCare.generateDefault(value)
      ),
      services: (item?.services || []).map((value) =>
        ServiceUtils.service.generateDefault(value)
      ),
      override_provider_services: item?.override_provider_services || false,
      data_quality: item?.data_quality || { geocoder_confidence: 0 },
    };
  },
};

const api = {
  list: async (params?: { service_provider?: number }) => {
    const resp = await axios.get<PointOfService[]>('pointofservice/list.php', {
      params: params,
    });
    return resp.data;
  },
  get: async (id: number) => {
    const resp = await axios.get<PointOfService>('pointofservice/get.php', {
      params: { id: id },
    });
    return resp.data;
  },
  create: async (item: Partial<PointOfService>) => {
    const resp = await axios.post<PointOfService>('pointofservice/create.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  update: async (item: Partial<PointOfService>) => {
    const resp = await axios.put<PointOfService>('pointofservice/update.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  remove: async (id: number) => {
    const resp = await axios.delete<PointOfService>(
      'pointofservice/remove.php',
      {
        params: { id: id },
      }
    );
    return resp.data;
  },
  generateSearchDataset: async (id?: number) => {
    const resp = await axios.get('pointofservice/generateSearchDataset.php', {
      params: { id: id },
    });
    return resp.data;
  },
};

export default { api, pointOfService, language_service_keys };
export { PointOfService };
