// import LocaleUtils from './LocaleUtils';
interface Marker {
  filename: string;
}

const marker = {
  generateDefault: (item?: Partial<Marker>): Partial<Marker> => {
    return {
      filename: item?.filename,
    };
  },

  iconSrcForPos: (pos?: any, sp?: any) => {
    // colour based on language services or designation
    const designation_key =
      pos?.language_designation_key || sp?.language_designation_key;
    const isFrenchServices = !!pos.language_services?.fr;
    let iconColor = 'def';
    if (designation_key) {
      iconColor =
        designation_key == 'DC' || designation_key == 'DP'
          ? 'fr'
          : designation_key == 'ID'
          ? 'pfr'
          : 'def';
    } else {
      iconColor = isFrenchServices ? 'frbl' : 'def';
    }

    const name =
      (pos?.name?.en ? pos.name.en : '') +
      ' ' +
      (sp?.name_long?.en ? sp.name_long.en : '');
    let iconType = 'default';

    switch (true) {
      case /[Hh]ospital/.test(name):
      case /[Hh][ôo]pital/.test(name):
        iconType = 'hosp';
        break;
      case /[Hh]ospice/.test(name):
        iconType = 'ltc';
        break;
      case /[M]ental/.test(name):
        iconType = 'mh';
        break;
      case /[Cc]linic/.test(name):
      case /[Mm]edical/.test(name):
        iconType = 'clinic';
        break;
      case /[Cc]ommunity/.test(name):
        iconType = 'comm';
        break;
      // case /^Dr\./.test(name):
      //   iconType = 'pc';
      //   break;
    }

    return require('@/assets/markers/' +
      iconType +
      '-' +
      iconColor +
      '-marker.png');
  },

  api: {
    list: () => {
      const markers = require
        .context('@/assets/markers', true, /^.*\.png$/)
        .keys();
      // markers.push("default");
      return markers.map(
        (i) => <Marker>marker.generateDefault({ filename: i.substr(2) })
      );
    },
  },
};

export default { marker };
export { Marker };
